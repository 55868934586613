<script>
import AppDialog from "@/components/AppDialog.vue";
import ProgresiveLoadAutocompleteOld from "@/components/utils/ProgresiveLoadAutocompleteOld.vue";
import ActividadesRoutes from "@/services/Actividad.services";
import ActividadesServices from "@/services/Actividad.services";
import DataTableComponent from "@/components/DataTableComponent.vue";
import DataTable from "@/components/DataTableComponent.vue";
import ConfirmationDialogComponent from "@/components/ConfirmationDialogComponent.vue";
import ListadoActividadPadre from "@/components/ListadoActividadPadre.vue";

const servicio = ActividadesRoutes.getActividad;

export default {
  name: "ActividadProveedor",
  components: {
    ListadoActividadPadre,
    ConfirmationDialogComponent, DataTable, DataTableComponent, ProgresiveLoadAutocompleteOld, AppDialog},
  props: {
    icon: {
      typeof: String,
    },
    label: {
      typeof: String,
    },
    id_proveedor: {
      typeof: Number,
      default: null,
    },
    pulsating: {
      typeof: String,
      default: ''
    }
  },
  data: () => ({
    modal_ayuda_rubros: false,
    showModal: false,
    modal_confirmacion: false,
    service: servicio,
    actividad: null,
    listado_actividades: [],
    callback: null,
    paginacion: {
      page: 1,
      per_page: 10,
    },
    total_rows: 0,
    isLoading: false,
    headers: [
      {
        text: 'Actividad',
        value: 'a.nombre',
        align: 'start'
      },
      {
        text: 'Catalogo',
        value: 'Catalogó',
        align: 'center'
      },
      {
        text: 'Acciones',
        value: 'actions',
        align: 'center'
      },
    ],
    actions: [
      {
        tooltip: {
          text: "Eliminar actividad",
          color: "red",
        },
        icon: {
          color: "red",
          name: "mdi-trash-can",
        },
        eventName: "eleminar",
      },
    ],
  }),
  methods: {
    async getActividades() {
      this.isLoading = true;
      const {data, headers} = await ActividadesServices.getActividadesByProv(this.id_proveedor, this.paginacion);
      this.isLoading = false;
      this.paginacion.page = Number(headers.page)
      this.total_rows = Number(headers.total_rows)
      this.paginacion.per_page = Number(headers.per_page)
      this.listado_actividades = data;
    },
    eleminar(item) {
      this.callback = () => this.services.ActividadesServices.deleteActividad(item.id);
      this.modal_confirmacion = true;
    },
    paginar({cantidad_por_pagina, pagina}) {
      this.paginacion.page = pagina;
      this.paginacion.per_page = cantidad_por_pagina;
      this.getActividades();
    },
    limpiarModal() {
      this.$emit("update:recargar");
      this.actividad = null;
      this.showModal = false;
    },
    async guardar() {
      await ActividadesServices.postActividad({ proveedor: this.id_proveedor, rubro: this.actividad});
      this.actividad = null;
      this.getActividades();
    },
  },
  watch: {
    id_proveedor: {
      handler: async function (val) {
        if (val) {
          this.isLoading = true;
          this.getActividades();
          // this.service = ActividadesRoutes.getActividadByProveedor(val);
          // this.isLoading = false;
        }
      },
      immediate: true,
    },
  },
}
</script>

<template>
  <div
    :class="pulsating"
    class="text-decoration-none px-4 py-4 d-flex flex-column align-center item-container mx-auto"
    style="cursor: pointer;"
    @click="showModal = true"
  >
    <v-icon
        color="primary"
        size="75px"
        class="btns"
    >
      {{ icon }}
    </v-icon>
    <p class="text-center mt-4 mb-0"> {{ label }} </p>

    <div>
      <AppDialog
          v-model="showModal"
          title="Listado de actividades"
          classButtons="d-flex justify-end mt-5 mr-7"
          :showClose="false"
          :maxWidth="1000">
        <template #content>
          <v-row >
            <v-col cols="12" v-if="haveRoles(['ROLE_PROVEEDOR_MANTENIMIENTO_ACTIVIDAD_LISTAR'])">
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    Agregar Actividades a Proveedor
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row>
                      <v-col cols="12" sm="10" class="d-flex gap-1 flex-wrap">
                        <ProgresiveLoadAutocompleteOld
                            v-model="actividad"
                            class="flex-grow-1 flex-shrink-1"
                            style="min-width: 200px"
                            label="Actividad"
                            placeholder="Seleccione una institución"
                            itemText="nombre"
                            itemValue="id"
                            hide-details
                            clearable
                            :service="service"
                            ref="progresive"
                            :id_prov="id_proveedor"
                        />
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                @click="modal_ayuda_rubros = true"
                                v-on="on"
                                v-bind="attrs"
                                size="50"
                                class="mdi-information-variant-circle-outline heartbeat">mdi-information-variant-circle-outline
                            </v-icon>
                          </template>
                          <span>Mostrar rubros disponibles</span>
                        </v-tooltip>
                      </v-col>
                      <v-col cols="12" sm="2">
                        <v-btn block class="mt-2" color="tableAccent white--text" @click="guardar">Agregar</v-btn>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
  
            </v-col>
  
            <v-col cols="12">
              <DataTableComponent
                  :items="listado_actividades"
                  :headers="headers"
                  :total_registros="total_rows"
                  :show_loading="isLoading"
                  :show_actions="false"
                  @eleminar="eleminar"
                  @paginar="paginar"
  
              >
                <template v-slot:[`item.actions`]={item}>
                  <v-tooltip top color="red" v-if="haveRole('ROLE_PROVEEDOR')">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                          @click="eleminar(item)"
                          v-bind="attrs"
                          v-on="on"
                          large
                          color="red"
                          :disabled="listado_actividades.length === 1 ? true : false "
                      >
                        mdi-trash-can
                      </v-icon>
                    </template>

                    <span>Eliminar actividad</span>
                  </v-tooltip>
                </template>
                <template v-slot:[`item.catalogo`]={item}>
                  <v-chip :color="item?.a?.ae?.color"> {{ item?.a?.ae?.nombre }}</v-chip>
                </template>
              </DataTableComponent>
            </v-col>
          </v-row>
        </template>
        <template #actions class="mt-5">
          <v-btn @click="limpiarModal()" outlined color="error">Cerrar</v-btn>
        </template>
      </AppDialog>
      <ConfirmationDialogComponent
          :show="modal_confirmacion"
          title="¿Deseas eliminar la actividad?"
          message="El cambio es irreversible"
          btnConfirmar="Continuar"
          @close="modal_confirmacion = false"
          :callback="callback"
          @cerrar="getActividades()"
      />
      <ListadoActividadPadre v-model="modal_ayuda_rubros"/>
  
    </div>
  </div>
</template>

<style lang="scss" scoped>
.customItem {
  width: 150px !important;
}

.customItem:hover {
  background: #313945;
  color: white !important;
  border-radius: 4px;

  .v-icon {
    color: white !important;
  }
}

.pulsating-chip {
  animation: pulsate 1s infinite;
}

@keyframes pulsate {
  0% {
    transform: scale(1);
    background-color: #e0f7fa;
    border-radius: 20%;
  }

  50% {
    transform: scale(0.9);
    background-color: #b2ebf2; /* cyan */
    border-radius: 0%;
  }

  100% {
    transform: scale(1);
    background-color: #e0f7fa; /* light cyan */
    border-radius: 20%;
  }
}
</style>